<template>
  <v-main>
    <v-row gutters="2" class="pt-0 pb-0">
      <v-col cols="12" md="12" class="pb-0 pb-0">
        <v-card>
          <v-card-text>
            <v-row gutters="2" justify="center">
              <v-col cols="12" md="12">
                <h3>{{ readmessage[0].Product }}</h3>
                <v-data-table
                  :headers="headers"
                  :items="readmessage"
                  :item-class="me_class"
                  :footer-props="{
                    itemsPerPageOptions: [-1, 30, 50, 100],
                  }"
                >
                  <template v-slot:[`item.firstname`]="{ item }">
                    <span v-if="item.userId">
                      {{ item.Campus }}
                    </span>
                    <span v-else>
                      {{ item.firstname }} {{ item.lastname }}
                    </span>
                  </template>
                  <template v-slot:[`item.messageId`]="{ item }">
                    <span
                      class="svg-icon svg-icon-md svg-icon-danger"
                      v-if="item.userId"
                    >
                      <v-icon
                        class="mr-2"
                        color="red"
                        v-on:click="removeMessage(item.messageId)"
                        dark
                        v-bind="attrs"
                      >
                        mdi-delete
                      </v-icon>
                    </span>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="reply"
                  outlined
                  :label="$t('MESSAGES.REPLY')"
                  type="text"
                >
                  <template v-slot:append>
                    <v-btn color="primary" @click="replyMsg()">
                      {{ $t("MESSAGES.REPLY") }}
                    </v-btn>
                  </template>
                </v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="isShowDialogDelete" max-width="350">
      <v-card>
        <v-card-title class="headline">
          {{ $t("MESSAGE.DELETE.MODAL_TITLE") }}
        </v-card-title>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="red darken-1" text @click="isShowDialogDelete = false">
            {{ $t("MESSAGE.DELETE.MODAL_NO") }}
          </v-btn>

          <v-btn color="primary darken-1" text @click="confirmDeleteMessage()">
            {{ $t("MESSAGE.DELETE.MODAL_YES") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-main>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { GET_MESSAGE } from "@/core/services/store/messages/messages.module";
export default {
  components: {},
  data() {
    return {
      isShowDialogDelete: false,
      deleteId: null,
      reply: "",
      headers: [
        {
          text: this.$t("MESSAGES.FROM"),
          value: "firstname",
          sortable: false,
        },
        {
          text: this.$t("MESSAGES.MESSAGE"),
          value: "messageDescription",
          sortable: false,
        },
        {
          text: this.$t("MESSAGES.DATE"),
          value: "created_at",
          sortable: false,
        },
        {
          text: this.$t("MESSAGES.ACTIONS"),
          value: "messageId",
          sortable: false,
        },
      ],
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("MESSAGES.READMESSAGE") },
    ]);
    this.$store.dispatch(GET_MESSAGE, "messageId=" + this.$route.params.id);
  },
  computed: {
    ...mapGetters(["readmessage"]),
  },
  methods: {
    ...mapActions(["replyMessage"]),
    ...mapActions(["deleteMessage"]),
    me_class(item) {
      if (item.userId === item.campusId) {
        return "me_row";
      }
    },
    replyMsg() {
      let data = {};
      data.studentId = this.readmessage[0].studentId;
      data.productId = this.readmessage[0].productId;
      data.userId = this.readmessage[0].campusId;
      data.messageId = this.$route.params.id;
      data.messageDescription = this.reply;
      this.replyMessage(data).then(() => {
        this.reply = "";
        this.$store.dispatch(GET_MESSAGE, "messageId=" + this.$route.params.id);
      });
    },
    removeMessage(messageId) {
      this.isShowDialogDelete = true;
      this.deleteId = messageId;
    },
    confirmDeleteMessage() {
      this.isShowDialogDelete = false;
      this.deleteMessage(this.deleteId).then(() => {
        this.$store.dispatch(GET_MESSAGE, "messageId=" + this.$route.params.id);
      });
    },
  },
};
</script>

<style lang="scss">
.me_row {
  background-color: rgb(218, 228, 255);
}
</style>
